import Vue, { reactive } from 'vue';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { clients, DeliveryRun, IDeliveryRunClient } from '@/api';
import downloadBlob from '@/helpers/download.helpers';

export class DeliveryDetailService {
    public state = reactive({
        delivery: null as DeliveryRun,
        notFound: false,
        loading: false,
    });

    constructor(private deliveryClient: IDeliveryRunClient) {}

    get delivery(): DeliveryRun {
        return this.state.delivery;
    }

    async cancelDelivery(Id: number): Promise<void> {
        if (!Id) {
            return;
        }
        
        try {
            await this.deliveryClient.cancel(Id);
            useAlertStore().setSuccess(
                    i18n.global.t('delivery.detail.cancelDelivery.successAlert.content'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right
                );
        } catch (e) {
            useAlertStore().setError(
                i18n.global.t('delivery.detail.cancelDelivery.errorAlert.content'),
                '',
                VerticalPosition.Bottom,
                HorizontalPosition.Right
            );

            throw e;
        }

        await this.getData(Id);
    }

    async init(deliveryId: number): Promise<void> {
        this.reset();
        await this.getData(deliveryId);
    }

    async getData(deliveryId: number) {
        try {
            this.state.loading = true;
            const delivery = await this.deliveryClient.get(deliveryId);
            this.state.delivery = delivery;
        } catch (error: any) {
            if (error.status === 404) {
                this.state.notFound = true;
            }
        } finally {
            this.state.loading = false;
        }
    }

    reset(): void {
        this.state.delivery = null;
        this.state.loading = false;
        this.state.notFound = false;
    }

    async download(): Promise<void> {
        if (!this.delivery?.id) {
            return;
        }
        const file = await this.deliveryClient.getFile(this.delivery?.id);

        return new Promise((resolve, reject) => {
            try {
                downloadBlob(file.data, file.fileName, () => {
                    resolve();
                });
            } catch (e) {
                reject(e);
            }
        });
    }
}

export const DeliveryDetailServiceInstance = new DeliveryDetailService(clients.DeliveryRunClient);
