<template>
  <form class="vl-form" @submit.prevent="onSubmit()">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-properties-list v-if="data.product">
          <vl-properties-label>{{ $t('product.detail.id.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.product.id }}</vl-properties-data>
          <vl-properties-label>{{ $t('product.detail.specification.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.product.specification && data.product.specification.label }}</vl-properties-data>
          <vl-properties-label>{{ $t('product.detail.status.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.product.status }}</vl-properties-data>
        </vl-properties-list>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving" :mod-loading="data.isSaving">{{
            $t('product.detail.cancelProduct.label')
          }}</vl-button>
          <vl-button :mod-disabled="data.isSaving" mod-icon-before mod-secondary tag-name="a" @click.prevent="cancel">
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import { onMounted, computed, reactive } from 'vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { useForm } from 'vee-validate';
import { ProductDetailService, ProductDetailServiceInstance } from '../../ProductDetail.service';

const productService: ProductDetailService = ProductDetailServiceInstance;

const props = defineProps({
  id: {
    type: Number,
    required: true
  }
});

const { handleSubmit, resetForm } = useForm({});

const data = reactive({
  product: null,
  isSaving: false
});

onMounted(async () => {
  await productService.init(props.id);
  if (productService.product) {
    data.product = productService.product;
  }
});

const onSuccess = async (values) => {
  await canceldelivery();
  closeModal();
};

const canceldelivery = async (): Promise<void> => {
  try {
    data.isSaving = true;
    await productService.cancelProduct(props.id);
  } catch (e) {
    console.error(e);
  } finally {
    data.isSaving = false;
  }
};

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped></style>