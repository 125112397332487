<template>
  <form class="vl-form" @submit.prevent="onSubmit()">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-properties-list v-if="data.definition">
          <vl-properties-label>{{ $t('productprocessdefinition.detail.id') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.id }}</vl-properties-data>
          <vl-properties-label>{{ $t('productprocessdefinition.detail.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.label }}</vl-properties-data>
          <vl-properties-label>{{ $t('productprocessdefinition.detail.organisation') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.organisation }} ({{ data.definition.ovoNumber }})</vl-properties-data>
        </vl-properties-list>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving" :mod-loading="data.isSaving">{{
            $t('productprocessdefinition.actions.dryrun.label')
          }}</vl-button>
          <vl-button :mod-disabled="data.isSaving" mod-icon-before mod-secondary tag-name="a" @click.prevent="cancel">
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { useProductRunStore } from '@/modules/configuration/store/product-run/product-run.store';
import { reactive } from 'vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { useForm } from 'vee-validate';

const detailStore = useProductProcessDefinitionDetailStore();
const runStore = useProductRunStore();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const { handleSubmit, resetForm } = useForm({});

const data = reactive({
  definition: null,
  isSaving: false,
});

onMounted(async () => {
  const productProcessDefinition = await detailStore.get(props.id, true);
  if (productProcessDefinition) {
    data.definition = productProcessDefinition;
  }
});

const onSuccess = async (values) => {
  await startdryrun();

  closeModal();
};

const startdryrun = async (): Promise<void> => {
  try {
    data.isSaving = true;
    await runStore.startdryrun(props.id);
  } catch (e) {
    console.error(e);
  } finally {
    data.isSaving = false;
  }
};

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped></style>
