import Vue, { reactive } from 'vue';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { clients, ProductRun, IProductRunClient } from '@/api';

export class ProductDetailService {
    public state = reactive({
        product: null as ProductRun,
        notFound: false,
        loading: false,
    });

    constructor(private productClient: IProductRunClient) {}

    get product(): ProductRun {
        return this.state.product;
    }

     async cancelProduct(Id: number): Promise<void> {
            if (!Id) {
                return;
            }
            
            try {
                await this.productClient.cancel(Id);
                useAlertStore().setSuccess(
                        i18n.global.t('product.detail.cancelProduct.successAlert.content'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right
                    );
            } catch (e) {
                useAlertStore().setError(
                    i18n.global.t('product.detail.cancelProduct.errorAlert.content'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right
                );
    
                throw e;
            }
    
            await this.getData(Id);
        }

    async init(productId: number): Promise<void> {
        this.reset();
        await this.getData(productId);
    }

    async getData(productId: number) {
        try {
            this.state.loading = true;
            const product = await this.productClient.get(productId);
            this.state.product = product;
        } catch (error: any) {
            if (error.status === 404) {
                this.state.notFound = true;
            }
        } finally {
            this.state.loading = false;
        }
    }

    reset(): void {
        this.state.product = null;
        this.state.loading = false;
        this.state.notFound = false;
    }
}

export const ProductDetailServiceInstance = new ProductDetailService(clients.ProductRunClient);
